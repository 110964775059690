import React, { useState } from "react";
import styled from "styled-components";
import { TextField, Grid, OutlinedInputProps } from "@material-ui/core";
import { Search as SearchIcon } from "@material-ui/icons";
import SubmitButton from "../components/SubmitButton";

interface Props {
  buttonLabel: string;
  placeholder?: string | undefined | null;
}

const JobSearch = ({ buttonLabel, placeholder }: Props) => {
  const [searchValue, setSearchValue] = useState("");

  const onChange = (value: string) => {
    setSearchValue(value);
  };

  return (
    <Wrapper>
      <form
        action={`https://karriere.depot-online.com/suchergebnisse/?L=0&id=103&q=${searchValue}`}
        method="post"
        target="_blank"
      >
        <Grid container>
          <SearchStyled item xs={12} md={7}>
            <TextFieldWrapper
              placeholder={placeholder!}
              InputProps={
                { disableUnderline: true } as Partial<OutlinedInputProps>
              }
              onChange={(event) => onChange(event.target.value)}
              value={searchValue}
            />
            <SearchIconStyled height={24} />
          </SearchStyled>
          <Grid
            item
            xs={12}
            md={3}
            container
            justify="flex-end"
            alignItems="flex-end"
          >
            <SubmitButton
              action={buttonLabel}
              variant="contained"
              backgroundColor
            ></SubmitButton>
          </Grid>
        </Grid>
      </form>
    </Wrapper>
  );
};

export default JobSearch;

const Wrapper = styled("div")(
  ({ theme }) => `
   background-color: ${theme.palette.blue.main};
   padding: ${theme.spacing(8, 3)};
   ${theme.breakpoints.up("sm")} {
    padding: ${theme.spacing(8, 6)};
    }
   ${theme.breakpoints.up("md")} {
    padding: ${theme.spacing(8, 2, 8, 19)};
    }
`
);
const TextFieldWrapper = styled(TextField)(
  ({ theme }) => `
   background-color: ${theme.palette.primary.contrastText};
   padding: ${theme.spacing(1, 3, 1, 6)};
  color: black;
  width: 100%;
  margin-right: ${theme.spacing(2)}px;
  text-transform: none;

  ${theme.breakpoints.up("sm")} {
  padding: ${theme.spacing(1, 3, 1, 9)};
  };
  input::placeholder{
    opacity: 1;
  };
`
);

const SearchStyled = styled(Grid)(
  ({ theme }) => `
  position: relative;
  margin-bottom: ${theme.spacing(1)}px;
  ${theme.breakpoints.up("md")} {
    margin-bottom: 0;
    };
`
);

const SearchIconStyled = styled(SearchIcon)(
  ({ theme }) => `
  position: absolute;
  left:${theme.spacing(2)}px;
  top: 12px;
`
);
