import React, { useState, useEffect } from "react";
import styled from "styled-components";
import axios from "axios";
import { Typography, Grid } from "@material-ui/core";
import JobFilter from "./JobFilter";
import JobCard from "./JobCard";
import LinkButton from "../components/LinkButton";
import { useIntl } from "gatsby-plugin-intl"
export interface Filter {
  label: string;
  url: string;
  number: number;
}

type HotJob = {
  descriptionfull: string;
  descriptionshort: string;
  division: string;
  employer: string;
  employmentmode: string;
  entrylevel: string;
  hotjob: boolean;
  identifier: string;
  jobcategory: string;
  location: { city: string };
  qualifications: string;
  responsibilities: string;
  scope: string;
  title: string;
  tooccupyfromtext: string;
  urlApplication: string;
  urlDetails: string;
  vacancycount: string;
  worktime: string;
};

const filterList = [
  { label: "Filiale" },
  { label: "Zentrale" },
  { label: "Logistik" },
  { label: "Außendienst" },
];

interface Props {
  hotjobsTitle: String;
  hotjobsSupertitle: String;
  hotjobsButtonLabel: String;
  findJobLabel: String;
  jobsearchInputLabel: String;
}

const HotJobs = ({
  hotjobsTitle,
  hotjobsSupertitle,
  hotjobsButtonLabel,
}: Props) => {
  const [selectedFilter, setFilter] = useState<string | undefined>(
    filterList[0].label
  );

  const [currentHotJobs, setCurrentHotJobs] = useState<HotJob[] | undefined>(
    undefined
  );

  const handleFetchHotJobs = () => {
    return axios.get(
      process.env.GATSBY_API_URL
        ? `${process.env.GATSBY_API_URL}/api/jobs/hotjobs/all/`
        : "/api/jobs/hotjobs/all/"
      // {
      //   auth: {
      //     username: process.env.GATSBY_API_CREDENTIALS!,
      //     password: process.env.GATSBY_API_CREDENTIALS!,
      //   },
      // }
    );
  };
  useEffect(() => {
    handleFetchHotJobs().then((result) => {
      setCurrentHotJobs(result.data.data);
    });
  }, []);

  let selectedcurrentHotJobs = currentHotJobs?.filter(
    (job) => job.jobcategory === selectedFilter
  );
  const intl = useIntl()
  return (
    <Wrapper>
      <Typography variant="body1">{hotjobsSupertitle}</Typography>
      <Headline container alignItems="center" justify="space-between">
        <Title item xs={12} sm={12} md={4} lg={4}>
          <Typography variant="h2">{hotjobsTitle}</Typography>
        </Title>
        <RightSide item xs={12} sm={12} md={12} lg={8}>
          <Filter>
            {filterList!.map((item) => (
              <JobFilter
                key={item!.label}
                handleClick={setFilter}
                filter={item.label}
                active={selectedFilter === item.label}
              >
                {intl.formatMessage({ id: `${item!.label}` })}

              </JobFilter>
            ))}
            <Button>
              <LinkButton href="https://karriere.depot-online.com/stellenangebote">
                {hotjobsButtonLabel}
              </LinkButton>
            </Button>
          </Filter>
        </RightSide>
      </Headline>
      <Grid container alignItems="flex-start">
        {!!selectedcurrentHotJobs?.length ? (
          selectedcurrentHotJobs?.map((job) => {
            return (
              <Grid item xs={12} md={4} key={job.identifier}>
                <JobCard
                  location={`${job.location.city}, Deutschland`}
                  jobDesignation={job.title}
                  urlDetails={job.urlDetails}
                  description={decodeURI(encodeURI(job.descriptionfull))}
                />
              </Grid>
            );
          })
        ) : (
          <Typography variant={"body1"}>
            {intl.formatMessage({ id: 'NoHotJobsFoundForThisCategory' })}
          </Typography>
        )}
      </Grid>
    </Wrapper>
  );
};

export default HotJobs;

const Wrapper = styled.div(
  ({ theme }) => `
background-color:${theme.palette.lightblue.main};
padding:${theme.spacing(5, 3, 3)};
margin-bottom: ${theme.spacing(6)}px;
${theme.breakpoints.up("sm")} {
padding:${theme.spacing(6)}px;
margin-bottom: ${theme.spacing(11)}px;
}
`
);

const Headline = styled(Grid)(
  ({ theme }) => `
  margin-bottom: ${theme.spacing(4)}px;
  ${theme.breakpoints.up("sm")} {
margin-bottom: ${theme.spacing(7)}px;
`
);
const Filter = styled.div(
  ({ theme }) => `
display: flex;
flex-direction: column;

${theme.breakpoints.up("sm")}{
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
}
`
);
const Title = styled(Grid)(
  ({ theme }) => `
margin-bottom: ${theme.spacing(3)}px;
${theme.breakpoints.up("md")}{
  margin-bottom: 0;
}
`
);
const RightSide = styled(Grid)(
  ({ theme }) => `
margin-bottom: ${theme.spacing(3)}px;
${theme.breakpoints.up("md")}{
  margin-bottom: 0;
}
`
);
const Button = styled.div(
  ({ theme }) => `
align-self: flex-start;
margin-top: ${theme.spacing(2)}px;
${theme.breakpoints.up("md")}{
  align-self: auto;

}
`
);
