import React from "react";

import { Button, Typography } from "@material-ui/core";

interface Props {
  action: string;
  variant?: "contained" | "outlined" | "text";
  backgroundColor?: boolean;
}

const SubmitButton = ({ action, variant, backgroundColor }: Props) => {
  return (
    <Button
      variant={variant}
      color="primary"
      size="large"
      style={{
        boxShadow: "none",
        backgroundColor: backgroundColor ? "primary" : "transparent",
        alignSelf: "flex-start",
      }}
      type="submit"
    >
      <Typography variant="body1">{action}</Typography>
    </Button>
  );
};

export default SubmitButton;
