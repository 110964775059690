import React, { FC } from "react";

import { Typography } from "@material-ui/core";
import styled from "styled-components";

interface Props {
  contained?: boolean;
  href: string;
}

const LinkButton: FC<Props> = ({ children, contained, href }) => {
  return (
    <Root
      href={href}
      target="_blank"
      rel="noopener noreferrer"
      contained={contained}
    >
      <Typography variant="body1">{children}</Typography>
    </Root>
  );
};

const Root = styled.a<Props>(({ theme, contained }) => ({
  display: "inline-block",
  padding: theme.spacing(1, 5),
  backgroundColor: contained ? theme.palette.primary.main : "none",
  textDecoration: "none",
  color: contained
    ? theme.palette.primary.contrastText
    : theme.palette.primary.main,
  border: contained ? "none" : `1px solid ${theme.palette.primary.main}`,
}));

export default LinkButton;
