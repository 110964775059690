import React from "react";
import Layout from "src/common/Layout";
import { graphql } from "gatsby";
import { CareerPageQuery } from "types/graphql-types";
import "react-json-pretty/themes/1337.css";
import CareerStage from "src/common/components/Stages/CareerStage";
import JobSearch from "src/common/career/JobSearch";
import HotJobs from "src/common/career/Hotjobs";
import styled from "styled-components";
import ContentWidthWrapper from "src/common/components/ContentWidthWrapper";
import SEO from "src/common/components/seo";
import { useIntl } from "gatsby-plugin-intl";

const career = ({
  data: { contentfulCareerPage, contentfulFooter, contentfulHeader },
}: {
  data: CareerPageQuery;
}) => {
  if (!contentfulCareerPage || !contentfulFooter || !contentfulHeader) {
    throw new Error(
      "Not all Contentful requirements met: contentfulCareerPage, contentfulFooter, contentfulHeader"
    );
  }
  const {
    pageConfig,
    findJobLabel,
    jobsearchInputLabel,
    hotjobsTitle,
    hotjobsSupertitle,
    hotjobsButtonLabel,
  } = contentfulCareerPage;
  const intl = useIntl()
  return (
    <Layout
      contentfulFooter={contentfulFooter}
      contentfulHeader={contentfulHeader}
      stageContent={
        <SpaceWrapper>
          <CareerStage
            title={pageConfig!.title!.title!}
            subtitle={pageConfig!.subtitle!.subtitle!}
            image={pageConfig!.headerImage!.file!.url!}
            anchorLink={`https://karriere.depot-online.com/`}
            linkLabel={findJobLabel}
            linkAsButton={false}
          />
        </SpaceWrapper>
      }
    >
      <SEO
        title={pageConfig!.seoConfig?.title!}
        description={pageConfig!.seoConfig?.description}
        image={pageConfig!.seoConfig?.image?.localFile?.publicURL!}
      />
      <ContentWidthWrapper>
        <JobSearch
          buttonLabel={intl.formatMessage({ id: 'jobSearchButton' })}
          placeholder={jobsearchInputLabel!}
        />
        <HotJobs
          hotjobsTitle={hotjobsTitle!}
          hotjobsSupertitle={hotjobsSupertitle!}
          hotjobsButtonLabel={hotjobsButtonLabel!}
          findJobLabel={findJobLabel!}
          jobsearchInputLabel={jobsearchInputLabel!}
        />
      </ContentWidthWrapper>
    </Layout>
  );
};

export const query = graphql`
  query CareerPage  ($locale:String!){
    contentfulCareerPage (node_locale: {eq: $locale}) {
      pageConfig {
        title {
          title
        }
        subtitle {
          subtitle
        }
        headerImage {
          file {
            url
          }
        }
        seoConfig {
          title
          description
          image {
            localFile {
              publicURL
            }
            file {
              url
            }
          }
        }
      }
      hotjobsTitle
      hotjobsSupertitle
      hotjobsButtonLabel
      findJobLabel
      jobsearchInputLabel
    }
    contentfulFooter (node_locale: {eq: $locale}){
      title
      text
      copyright
      columnOneTitle
      columnOneLinks {
        id
        label
        url
        type
      }
      columnTwoTitle
      columnTwoLinks {
        id
        label
        url
        type
      }
      columnThreeTitle
      columnThreeLinks {
        id
        label
        url
        type
      }
      instagramLink
      facebookLink
      pinterestLink
      columnFourTitle
      childContentfulFooterLanguagesListJsonNode {
        de
        en
      }
      chosenLang
      deGermanFlag {
        file {
          url
        }
      }
      enEnglishFlag {
        file {
          url
        }
      }
      chosenFlag {
        file {
          url
          fileName
        }
      }
    }
    contentfulHeader (node_locale: {eq: $locale}){
      links {
        id
        label
        url
        type
      }
    }
  }
`;

export default career;

const SpaceWrapper = styled.div(
  ({ theme }) => `
  padding-top: ${theme.spacing(8)}px;
  ${theme.breakpoints.up("md")} {
padding-top: ${theme.spacing(15)}px;
max-width: 1280px;
 margin: 0 auto;
  }
`
);
