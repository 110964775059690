import React from "react";

import Dotdotdot from "react-dotdotdot";
import { Typography } from "@material-ui/core";
import styled from "styled-components";

interface Props {
  location: string;
  jobDesignation: string;
  description: string;
  urlDetails: string;
}

const JobTitle = styled("a")`
  display: block;
  font-size: 16px;
  line-height: 1.5;
  color: #222222;
  text-decoration: none;

  margin-bottom: 16px;

  &:visited {
    color: #222222;
    text-decoration: none;
  }
`;

const JobCard = ({
  location,
  jobDesignation,
  description,
  urlDetails,
}: Props) => {
  return (
    <CardWrapper>
      <Typography variant="caption" gutterBottom>
        {location}
      </Typography>
      <Dotdotdot clamp={2}>
        <JobTitle href={urlDetails} rel="noopener noreferrer">
          {jobDesignation}
        </JobTitle>
      </Dotdotdot>
      <Dotdotdot clamp={4}>
        <Typography
          variant="body2"
          dangerouslySetInnerHTML={{
            __html: description.replace(/<\/?[^>]+(>|$)/g, ""),
          }}
        ></Typography>
      </Dotdotdot>
    </CardWrapper>
  );
};

export default JobCard;

const CardWrapper = styled.section(
  ({ theme }) => `

    margin-bottom: ${theme.spacing(5)}px;
    ${theme.breakpoints.up("md")} {
      padding-right: 56px;
    }
  `
);
