import styled from "styled-components";
import React, { ReactNode } from "react";
import { Button } from "@material-ui/core";

interface Props {
  filter: string;
  handleClick: (item: string) => void;
  children: ReactNode;
  active: boolean;
}

const JobFilter = ({ handleClick, children, filter, active }: Props) => {
  return (
    <JobFilterStyled active={active} onClick={() => setFilter()}>
      <Button>{children}</Button>
    </JobFilterStyled>
  );

  function setFilter() {
    return handleClick(filter);
  }
};

const JobFilterStyled = styled.div<{ active: boolean }>(
  ({ theme, active }) => `
  >Button {
  color: ${theme.palette.primary.main};
  font-weight: ${active ? "bold" : "normal"};
  font-size: 1rem;
  line-height: 1;
  text-decoration: ${active ? "underline" : "none"};
  margin: ${theme.spacing(2, 3, 1, 0)};
  padding: 0;
background-color: transparent;
  ${theme.breakpoints.up("md")}{
    margin-bottom: 0;
  }
}
`
);

export default JobFilter;
